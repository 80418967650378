<template>
  <div class="business-wrap">
    <card-header :title='menuData.name' @deleteData='deleteData'  :edit="edit" :menuData='menuData'>
    </card-header>
    <div class='equal-main'>
      <en-table :data="tableData" :loading="loading"  max-height="100%" @row-click="handleRowClick">
        <table-column v-for="(col, index) in headList" is-home-page :key="(col.id + index)||index" :filterSortConfig="null" :data="col">
        </table-column>
      </en-table>
    </div>
  </div>
</template>

<script>
import API from "@/api/businessModel/list";
import { businessListEntranceRoute } from "@/components/businessDetail/helper";
import tableColumn from "@/components/en-table-extra/tableColumn";
import CardHeader from "@/views/home/components/card-header.vue";

export default {
  name: "BusinessList",
  props: {
    menuData: {
      type: Object
    },
    edit: {
      default: false
    }
  },
  components: {
    CardHeader
    // tableColumn
  },
  filters: {
  },
  data() {
    return {
      loading: false,
      tableData: [],
      headList: [],
      hideFields: []
    };
  },
  mounted() {
    this.queryHeadListAndDataList();
  },
  methods: {
    editTheme() {
        this.$emit("editTheme");
    },
    deleteData() {
      this.$emit("deleteData");
    },
    toBuisnessList() {
      this.$router.push({
        path: "/businessModel/list",
        query: {
          templateId: this.menuData.refData.templateId || this.menuData.refData.id,
          templateName: this.menuData.refData.templateName || this.menuData.refData.name,
          type: this.menuData.refData.type
}
      });
    },
    handleRowClick({ row }) {
      console.log("行点击");
      console.log(row.id);
      const { id: templateId, type } = this.menuData.refData;
      const route = businessListEntranceRoute({
        templateId, id: row.id, businessType: type
      });
      this.$router.push(route);
    },
    // 查询业务建模表头和列表
    async queryHeadListAndDataList(pageNo = 1, pageSize = 10) {
      try {
        this.loading = true;
        const res = await API.queryHeadList({
          templateId: this.menuData.refId
        });
        this.headList = res.headList;
        this.hideFields = res.hideFields;
        const { records } = await API.queryDataList({
          pageNo,
          pageSize,
          templateId: this.menuData.refId
        }, this.headList);
        this.tableData = records;
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.business-wrap {
  height: 100%;
  .header-right-arrow{
    width: 18px;
    height: 18px;
  }
  .equal-main {
    position: relative;
    height: calc(100% - (20px + 30px))!important;
    clear: both;
    .en-icon {
      display: inline-block;
      vertical-align: middle;
      margin-top: -2px;
    }
  }
  .file-name {
    span {
      margin-left: 5px;
    }
  }
  .appendix-btn {
    cursor: pointer;
    color: #3e90fe;
  }
  /deep/ .el-tree {
    .el-tree-node__label {
      font-size: 12px;
    }
  }
}

</style>
