var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "business-wrap" },
    [
      _c("card-header", {
        attrs: {
          title: _vm.menuData.name,
          edit: _vm.edit,
          menuData: _vm.menuData,
        },
        on: { deleteData: _vm.deleteData },
      }),
      _c(
        "div",
        { staticClass: "equal-main" },
        [
          _c(
            "en-table",
            {
              attrs: {
                data: _vm.tableData,
                loading: _vm.loading,
                "max-height": "100%",
              },
              on: { "row-click": _vm.handleRowClick },
            },
            _vm._l(_vm.headList, function (col, index) {
              return _c("table-column", {
                key: col.id + index || index,
                attrs: {
                  "is-home-page": "",
                  filterSortConfig: null,
                  data: col,
                },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }